<template>
  <Container>
    <div class="glossary">
      <GlossaryEntry
        v-for="element in element.nestedElements"
        :key="element.id"
        :element="element"
      />
    </div>
  </Container>
</template>

<script>
  import GlossaryEntry from "./glossary_entry"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: {
      GlossaryEntry,
    },
    mixins: [AlchemyElement],
  }
</script>

<style lang="scss" scoped>
  @import "~/assets/styles";

  .glossary {
    display: table;
    @include viewport("mini", "sm") {
      display: block;
    }
  }
</style>
