<template>
  <dl class="glossary-entry">
    <dt class="glossary-term">
      {{ term }}
    </dt>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <dd class="glossary-description" v-html="description" />
  </dl>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      term() {
        return this.getValue("term")
      },
      description() {
        return this.getValue("description")
      },
    },
  }
</script>

<style lang="scss" scoped>
  .glossary-entry {
    display: table-row;
    > * {
      display: table-cell;
      padding-bottom: $space-s;
    }
    @include viewport("mini") {
      &,
      > * {
        display: block;
        padding-bottom: 0;
      }
    }
  }

  .glossary-term {
    font-weight: bold;
    padding-right: $gutter;
    width: 160px;
    @include viewport("mini") {
      margin-top: $gutter;
      margin-bottom: 0;
      text-align: left;
      width: 100%;
    }
  }

  .glossary-description {
    @include viewport("mini") {
      margin: 0;
    }

    &:deep(p) {
      margin-top: 0;
    }
  }
</style>
